.orders-header{
  background-color: $white;
  padding: 1.125rem 1.25rem;
  text-align: center;
  &__logo{
    display: block;
    width: 125px;
    margin: 0 auto 1.25rem;
  }
  &__title{
    font-weight: 600;
    font-size: 1.125rem;
    letter-spacing: -.02em;
    color: $dark-blue;
  }
}