.fav-btn{
  @include btn-reset;
  background: #fff;
  box-shadow: 0 .25rem .25rem rgba(0, 0, 0, .08);
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  svg{
    width: .875rem;
    height: .75rem;
    fill: $dark-grey;
  }
  &.active{
    background-color: #DB3022;
    box-shadow: 0 .25rem .25rem rgba(219, 48, 34, .16);
    svg{fill: $white;}
  }
}