.order-confirm{
  min-height: 100vh;
  background: center center no-repeat;
  background-size: cover;
  text-align: center;
  color: $white;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-direction: column;
  padding: 2rem 1.25rem;
  &__footer{margin-top: 2rem;}
  &__main{
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  &__title{
    font-weight: 600;
    font-size: 1.625rem;
    color: $yellow;
    margin-bottom: 1.25rem;
  }
  &__txt{
    margin-bottom: 2.5rem;
    font-size: 1rem;
    line-height: 150%;
    strong{
      font-size: 400;
      color: $yellow;
    }
    &:last-child{margin-bottom: 0;}
  }
}