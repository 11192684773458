.address-item{
  padding: .9375rem .75rem;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  background-color: $clr-bg-main;
  border: 1px solid $clr-border;
  border-radius: .5rem;
  margin-bottom: 1.125rem;
  &:last-child{margin-bottom: 0;}
  &__pic{
    width: calc(50% - .3125rem);
    background: center center no-repeat;
    background-size: cover;
  }
  &__main{width: calc(50% - .3125rem);}
  &__title{
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: .8em;
    color: $black;
  }
  &__txt{
    font-size: .75rem;
    font-weight: 600;
    color: $dark-grey;
    margin-bottom: .5rem;
  }
  &__status{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    &-icn{
      margin-right: .75rem;
      width: 2rem;
      height: 1.875rem;
      svg{
        width: 100%;
        height: 100%;
      }
    }
    &-txt{
      font-weight: 600;
      font-size: .6875rem;
      line-height: 120%;
    }
    &.opened{
      color: $green;
      svg{fill: $green;}
    }
    &.closing-soon{
      color: $red;
      svg{fill: $red;}
    }
    &.closed{
      color: $grey;
      svg{fill: $grey;}
    }
  }
  &__schedule{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: .5rem;
    &-icn{
      margin-right: .75rem;
      width: 2rem;
      height: 1.875rem;
      svg{
        width: 100%;
        height: 100%;
      }
    }
    &-txt{
      font-weight: 600;
      font-size: .6875rem;
      line-height: 120%;
    }
  }
}