.filter{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1.375rem;
  &__btn{
    @include btn-reset;
    height: 1.875rem;
    line-height: 1.875rem;
    padding: 0 1rem;
    border-radius: .9375rem;
    font-size: .875rem;
    white-space: nowrap;
    color: $dark-blue;
    &.active{
      background-color: $blue;
      color: $white;
    }
  }
}