.number{
  width: 6.75rem;
  position: relative;
  &__input{
    @include input-reset;
    width: 100%;
    height: 2.25rem;
    text-align: center;
    background-color: transparent;
    color: #222;
  }
  &__btn{
    @include btn-reset;
    position: absolute;
    z-index: 2;
    top: 0;
    background-color: $white;
    border-radius: 50%;
    font-size: 0;
    width: 2.25rem;
    height: 2.25rem;
    box-shadow: 0 .25rem .5rem rgba(0, 0, 0, .1);
    &::before,
    &::after{
      content: '';
      width: .875rem;
      height: 2px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -1px 0 0 -.4375rem;
      background-color: #9B9B9B;
    }
    &_minus{left: 0;}
    &_plus{
      right: 0;
      &::after{transform: rotate(90deg);}
    }
  }
}
