.profile-header{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 300;
  border-bottom: 1px solid rgba(153, 153, 153, .3);
  background-color: $white;
  height: 4rem;
  padding: 0 1.25rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &__title{
    font-weight: 600;
    font-size: 1.125rem;
    letter-spacing: -.02em;
    color: $dark-blue;
  }
}
.profile-main{
  padding-top: 4rem;
  min-height: calc(100vh - 4.375rem);
  background-color: $light-grey;
  &__title{
    padding: 0 1.25rem;
    margin: 1rem 0;
    font-weight: 600;
    font-size: 1.625rem;
    color: $dark-blue;
    text-align: left;
  }
}