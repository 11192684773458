.intro{
  position: fixed;
  z-index: 900;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: $black;
  &__footer{
    position: absolute;
    z-index: 100;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0 1.25rem 3.75rem;
  }
  &__slider{
    width: 100%;
    height: 100%;
    position: relative;
  }
  &__pagination{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 2.5rem 1.25rem 0;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    z-index: 10;
    .swiper-pagination-bullet{
      display: block;
      flex-grow: 1;
      margin: 0 .125rem;
      height: .25rem;
      background-color: $light-grey;
      border-radius: .125rem;
      overflow: hidden;
      &::before{
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background-color: $yellow;
      }
      &-active{
        &::before{
          width: 0;
          animation: widthIn 5s linear both;
        }
        & ~ .swiper-pagination-bullet::before{width: 0;}
      }
    }
  }
}
.intro-slide{
  width: 100%;
  height: 100vh;
  background: center 0 no-repeat;
  background-size: cover;
  padding: 5.25rem 1.25rem 0;
  color: $white;
  &__title{
    margin-bottom: .75rem;
    font-weight: 600;
    font-size: 1.625rem;
    line-height: 133%;
  }
  &__txt{
    font-weight: 300;
    font-size: 1rem;
    line-height: 150%;
  }
}

@keyframes widthIn {
  from { width: 0; }
  to { width: 100%; }
}