@font-face {
    font-family: 'MuseoSansCyrl';
    src: url('../fonts/MuseoSansCyrl-100.eot');
    src: local('☞MuseoSansCyrl-100'), local('MuseoSansCyrl-100'),
        url('../fonts/MuseoSansCyrl-100.eot?#iefix') format('embedded-opentype'),
        url('../fonts/MuseoSansCyrl-100.woff2') format('woff2'),
        url('../fonts/MuseoSansCyrl-100.woff') format('woff'),
        url('../fonts/MuseoSansCyrl-100.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: 'MuseoSansCyrl';
    src: url('../fonts/MuseoSansCyrl-300.eot');
    src: local('☞MuseoSansCyrl-300'), local('MuseoSansCyrl-300'),
        url('../fonts/MuseoSansCyrl-300.eot?#iefix') format('embedded-opentype'),
        url('../fonts/MuseoSansCyrl-300.woff2') format('woff2'),
        url('../fonts/MuseoSansCyrl-300.woff') format('woff'),
        url('../fonts/MuseoSansCyrl-300.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'MuseoSansCyrl';
    src: url('../fonts/MuseoSansCyrl-500.eot');
    src: local('☞MuseoSansCyrl-500'), local('MuseoSansCyrl-500'),
        url('../fonts/MuseoSansCyrl-500.eot?#iefix') format('embedded-opentype'),
        url('../fonts/MuseoSansCyrl-500.woff2') format('woff2'),
        url('../fonts/MuseoSansCyrl-500.woff') format('woff'),
        url('../fonts/MuseoSansCyrl-500.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'MuseoSansCyrl';
    src: url('../fonts/MuseoSansCyrl-700.eot');
    src: local('☞MuseoSansCyrl-700'), local('MuseoSansCyrl-700'),
        url('../fonts/MuseoSansCyrl-700.eot?#iefix') format('embedded-opentype'),
        url('../fonts/MuseoSansCyrl-700.woff2') format('woff2'),
        url('../fonts/MuseoSansCyrl-700.woff') format('woff'),
        url('../fonts/MuseoSansCyrl-700.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}