.banner-section{
  margin: 3.25rem 0 2.1875rem;
  position: relative;
  padding: 1.5625rem 1.25rem .875rem;
  overflow: hidden;
  &::before,
  &::after{
    content: '';
    display: block;
    background: url(../img/svg/broken-line.svg) 0 0 no-repeat;
    background-size: contain;
    width: 17rem;
    height: .3125rem;
    position: absolute;
  }
  &::before{
    top: 0;
    right: 6.5rem;
  }
  &::after{
    bottom: 0;
    left: 12.375rem;
  }
  &__txt{
    margin-top: .625rem;
    font-weight: 300;
    font-size: .75rem;
    color: $dark-grey;
    p{
      margin: 1em 0;
      &:first-child{margin-top: 0;}
      &:last-child{margin-bottom: 0;}
    }
  }
}