.footer-bar{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: .375rem 0;
}
.reset-button,
.submit-button{
  @include btn-reset;
  width: calc(50% - .5rem);
  height: 2.875rem;
  text-align: center;
  border-radius: .25rem;
  font-weight: 300;
  font-size: .875rem;
  color: $dark-blue;
}
.submit-button{
  background-color: $yellow;
}
.reset-button{
  border: 1px solid $grey;
}