.slide-bar{
  padding: .5625rem 0;
  &__track{
    position: relative;
    height: 5px;
  }
  &__thumb{
    position: absolute;
    top: 0;
    width: calc(100% / 3);
    height: 100%;
    background-color: $black;
    border-radius: 3px;
  }
}