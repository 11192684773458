.banner{
  display: block;
  overflow: hidden;
  border-radius: .5rem;
  position: relative;
  &__btn{
    position: absolute;
    z-index: 2;
    padding: .625rem 1.125rem .5rem 1.125rem;
    height: 1.875rem;
    border-radius: .9375rem;
    white-space: nowrap;
    bottom: .5rem;
    right: .625rem;
    background: linear-gradient(180deg, #dc3224 0%, #b90503 100%);
    font-weight: 600;
    font-size: .625rem;
    letter-spacing: .06em;
    text-transform: uppercase;
    color: $white;
    text-shadow: 0 1px 0 rgba(0, 0, 0, .25);
  }
}