.catalog-item{
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  margin-bottom: 2.5rem;
  &__img{
    width: 9.375rem;
    height: 9.375rem;
    border-radius: .5rem;
    background: center center no-repeat;
    background-size: cover;
  }
  &__pic{position: relative;}
  &__category{
    position: absolute;
    z-index: 2;
    top: .625rem;
    left: .375rem;
    white-space: nowrap;
    height: 1.5rem;
    line-height: 1.5rem;
    padding: 0 .75rem;
    border-radius: .75rem;
    font-size: .75rem;
    &_new{
      color: $white;
      background-color: $dark-blue;
    }
    &_pop {
      background-color: $yellow;
      color: $dark-blue;
    }
  }
  &__content{
    width: calc(100% - 9.375rem);
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-direction: column;
    padding-left: 1.375rem;
  }
  &__name{
    margin-bottom: .5rem;
    font-weight: 600;
    font-size: 1rem;
    color: $black;
  }
  &__txt{
    margin-bottom: .5rem;
    font-weight: 300;
    font-size: .75rem;
    color: $dark-blue;
  }
  &__price{
    width: 7.125rem;
    height: 2.25rem;
    line-height: calc(36px - 2px);
    border: 1px solid $dark-blue;
    border-radius: 1.125rem;
    font-size: .875rem;
    text-align: center;
    color: $dark-blue;
  }
}