.search{
  @include btn-reset;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  &__txt{
    font-weight: 300;
    font-size: .875rem;
    text-align: right;
    color: $dark-blue;
  }
  &__icn{
    width: 1.5rem;
    height: 1.5rem;
    margin-left: .375rem;
    svg{
      width: 100%;
      height: 100%;
    }
  }
}