.main-button{
  @include btn-reset;
  width: 100%;
  text-align: center;
  height: 3.375rem;
  padding-top: .25rem;
  background-color: $yellow;
  border-radius: .5rem;
  font-weight: 300;
  font-size: 1.25rem;
  color: $dark-blue;
  &:disabled{
    user-select: none;
    pointer-events: none;
    background-color: $light-grey;
  }
}