.popup-overlay{  
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 550;
  background-color: rgba(0, 0, 0, .26);
  animation: fadeIn .25s both;
}
.popup{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 590;
  background-color: $white;
  box-shadow: 0 -.25rem 1.25rem rgba(0, 0, 0, .06);
  border-radius: .5rem .5rem 0 0;
  overflow: hidden;
  max-height: 100vh;
  &__swiped{
    .popup__main{padding-top: 2rem;}    
  }
  &__main{
    max-height: 100vh;
    overflow-y: auto;
    padding: 1rem 1.25rem 5.7rem;
  }
}
.popup-arrow{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: $white;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.popup-title{
  text-align: center;
  margin: 1rem 0 1.25rem;
  padding: 0 1.25rem;
  color: $dark-blue;
  font-weight: 600;
  font-size: 1.125rem;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}