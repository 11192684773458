.params{
  border-bottom: .3125rem solid transparentize($grey, .7);
  width: calc(100% + 2.5rem);
  margin-left: -1.25rem;
}
.param{
  display: flex;
  align-items: center;
  justify-content: space-between;  
  height: 3.75rem;
  padding: 0 1.25rem;
  border-bottom: 1px solid $light-grey;
  &:last-child{border-bottom: none;}
  &__icn{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    margin-right: 1.25rem;
  }
  &__arrow{
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 1.25rem;
    svg{
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  &__txt{
    flex-grow: 1;
    color: $black;
    font-weight: 300;
    font-size: .875rem;
  }
  &__dop{opacity: 0;}
  input{
    display: none;
    &:checked ~ .param__dop{opacity: 1;}
  }
  &__arrow{
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 1.25rem;
    svg{
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}