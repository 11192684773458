.orders-footer{
  padding-bottom: 7.5rem;
  background-color: $light-grey;
  &__input{
    @include input-reset;
    text-align: center;
    height: 3.375rem;
    text-align: center;
    background-color: $white;
    border-top: 1px solid $clr-border;
    border-bottom: 1px solid $clr-border;
    text-transform: uppercase;
    font-weight: 600;
    font-size: .875rem;
    color: $dark-blue;
    &::-webkit-input-placeholder{opacity: 1; color: $yellow;}
    &::-moz-placeholder{opacity: 1; color: $yellow;}
    &:-moz-placeholder{opacity: 1; color: $yellow;}
    &:-ms-input-placeholder{opacity: 1; color: $yellow;}
    &:placeholder{opacity: 1;color: $yellow;}
    &:focus:required:valid{color: $dark-blue;}
    &:required:valid{color: $dark-blue;}    
  }
  &__main{padding: 1.5rem 1.25rem;}
  &__row{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: .75rem;
    font-weight: 600;
    font-size: .875rem;
    color: $dark-blue;
    &:last-child{margin-bottom: 0;}
  }
}