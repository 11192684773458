.sms-code{
  display: flex;
  align-items: stretch;
  justify-content: center;
  margin-top: 2rem;
  &__input{
    @include input-reset;
    margin: 0 .25rem;
    width: 3.125rem;
    height: 3.375rem;
    text-align: center;
    font-weight: 300;
    font-size: 1.25rem;
    font-weight: bold;
    border-bottom: 1px solid $white;
    color: $white;
  }
}