.city-select{
  &__pic{
    padding: 1.75rem 1.25rem;
    svg{
      display: block;
      margin: 0 auto;
      width: 8.75rem;
    }
  }
  &__title{
    margin-bottom: .875rem;
    font-weight: 600;
    font-size: 1.625rem;
    line-height: 150%;
    text-align: center;
    color: $black;
  }
}

.city-select-search{
  border-top: 1px solid $light-grey;
  border-bottom: 1px solid $light-grey;
  &__label{
    display: block;
    position: relative;
    width: 100%;
  }
  &__input{
    @include input-reset;
    padding: 0 1.25rem 0 4.125rem;
    height: 3rem;
    font-weight: 300;
    font-size: 1rem;
    color: $black;
    &::-webkit-input-placeholder{opacity: 1; color: $dark-grey;}
    &::-moz-placeholder{opacity: 1; color: $dark-grey;}
    &:-moz-placeholder{opacity: 1; color: $dark-grey;}
    &:-ms-input-placeholder{opacity: 1; color: $dark-grey;}
    &:placeholder{opacity: 1;color: $dark-grey;}
    &:focus:required:valid{color: $black;}
    &:required:valid{color: $black;}    
  }
  &__icn{
    position: absolute;
    left: 1.25rem;
    top: 50%;
    transform: translateY(-50%);
    svg{
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}

.city-select-location{
  display: block;
  padding: 1.25rem 1.25rem 1.75rem 4.125rem;
  position: relative;
  &__icn{
    position: absolute;
    left: 1.25rem;
    top: 1.2rem;
    svg{
      width: 1.5rem;
      height: 1.5rem;
    }
  }
  &__title{
    margin-bottom: .375rem;
    line-height: 150%;
    color: $dark-blue;
    font-size: 1rem;
    font-weight: 400;
  }
  &__txt{
    font-weight: 300;
    font-size: .875rem;
    line-height: 120%;
    color: $dark-grey;
  }
}
.city-select-list{
  border-top: .3125rem solid rgba(245, 245, 245, .5);
  li{
    border-bottom: 1px solid $light-grey;
    &:last-child{border-bottom: none;}
  }  
}

.city-select-item{
  display: block;
  height: 2.625rem;
  line-height: 2.625rem;
  padding: 0 1.25rem;
  font-weight: 300;
  font-size: 1rem;
  color: $black;
}