.item{
  width: 9.375rem;
  position: relative;
  &__pic{
    display: block;
    width: 100%;
    padding-top: 80%;
    border-radius: .5rem;
    background: center center no-repeat;
    background-size: cover;
  }
  &__category{
    position: absolute;
    z-index: 2;
    top: .625rem;
    left: .5rem;
    white-space: nowrap;
    padding: .25rem .75rem;
    font-size: .75rem;
    border-radius: .75rem;
    &_new{
      background-color: $dark-blue;
      color: $white;
    }
    &_pop{
      background-color: $yellow;
      color: $dark-blue;
    }
  }
  &__main{
    position: relative;
    padding-top: .625rem;
  }
  &__fav-btn{
    position: absolute;
    z-index: 3;
    top: 0;
    right: 0;
    transform: translateY(-50%);
  }
  &__price{
    margin-bottom: .25rem;font-weight: 300;
    font-size: .6875rem;
    color: $dark-grey;
    opacity: .77;
  }
  &__title{
    font-weight: 600;
    font-size: 1rem;
    color: $black;
    display: block;
    &_overflow{
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}