.orders-item{
  margin-bottom: 1.25rem;
  filter: drop-shadow(0 1px 1.5rem rgba(0, 0, 0, .12));
  background-color: $white;
  overflow: hidden;
  border-radius: .5rem;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  &__pic{
    width: 6.5rem;
    background: center center no-repeat;
    background-size: cover;
  }
  &__main{
    width: calc(100% - 6.5rem);
    position: relative;
    padding: .75rem .5rem .75rem 1.25rem;
  }
  &__context{
    @include btn-reset;
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    svg{
      width: 100%;
      height: 100%;
    }
  }
  &__title{
    padding-right: 2rem;
    margin-bottom: .25rem;
    font-weight: 600;
    font-size: 1rem;
    color: $black;
  }
  &__row{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: .75rem;
  }
  &__txt{
    width: calc(50% - .25rem);
    font-size: .8125rem;
    color: $dark-grey;
  }
  &__bar{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__price{
    font-size: 1rem;
    text-align: right;
    color: $dark-blue;
  }
}