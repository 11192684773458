.size-select{
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: .5rem 0 1rem;
  padding: 0 1.25rem;
}

.size-item{
  width: calc(50% - .5rem);
  margin-bottom: .625rem;
  display: block;
  height: 4.375rem;
  background-color: $light-grey;
  position: relative;
  border: 1px solid $grey;
  border-radius: .5rem;
  padding: .75rem .75rem .5rem;
  &__row{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: .75rem;
    &:last-child{margin-bottom: 0;}
  }
  &__txt{
    font-weight: 300;
    font-size: .6875rem;
    color: $black;
  }
  &__price{
    font-weight: 600;
    font-size: 1.125rem;
  }
  &__radio{
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 50%;
    border: 1px solid $grey;
    box-shadow: 0 0 0 2px $white inset, 0 0 0 3px $dark-blue inset;
  }
  &__input{
    input{
      display: none;
      &:checked + .size-item__radio{background-color: $dark-blue;}
    }
  }
}