.addresses{
  margin-top: 2.75rem;
  padding: 0 1.25rem 2.75rem;
  &__list{margin-bottom: 3.375rem;}
}

.addresses-item{
  margin-bottom: 1.25rem;
  padding: 1.25rem;
  background-color: $white;
  box-shadow: 0 1px 1.5rem rgba(0, 0, 0, .12);
  border-radius: .5rem;
  &__name{
    margin-bottom: .75rem;
    font-weight: 600;
    font-size: 1.375rem;
    color: $dark-blue;
  }
  &__txt{
    font-size: 1rem;
    color: $black;
    margin-bottom: 1.875rem;
  }
  &__bar{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.addresses-item-btn{
  @include btn-reset;
  display: flex !important;
  align-items: center;
  justify-content: flex-start;
  color: $blue;
  font-size: .875rem;
  &__icn{
    width: 1rem;
    height: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: .375rem;
    svg{
      display: block;
      height: 100%;
    }
  }
}