.profile-home{
  min-height: calc(100vh - 4.375rem);
  //padding-bottom: 4rem;
  background-color: $light-grey;
}

.profile-home-header{
  position: relative;
  &__top{
    height: 4.55rem;
    background-color: $white;
    padding: 1.25rem;
  }
  &__logo{
    margin: 0 auto;
    width: 7.8125rem;
  }
  &__name{
    text-align: center;
    padding: 2rem 1.25rem .75rem;
    color: $dark-blue;
    font-weight: 600;
    font-size: 1.125rem;
  }
}

.profile-home-nav{
  background-color: rgba(208, 208, 208, .1);
  padding: .625rem 0;
  ul{
    li{
      &:first-child{margin-bottom: .5rem;}
    }
  }
}

.profile-home-nav-item{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid $light-grey;
  border-bottom: 1px solid $light-grey;
  height: 3.75rem;
  padding: 0 1.25rem;
  color: $black;
  font-size: 1rem;
  background-color: $white;
  &__txt{
    flex-grow: 1;
    padding-top: .2rem;
  }
  &__arrow{
    width: 1.5rem;
    height: 1.5rem;
    svg{
      display: block;
      width: 100%;
      height: 100%;
      fill: #9B9B9B;
    }
  }
  &_fav{
    background-color: $yellow;
    color: $white;
    .profile-home-nav-item__arrow svg{fill: $white;}
  }
  &__icn{
    width: 2.25rem;
    height: 2.25rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white;
    box-shadow: 0 .25rem .25rem rgba(0, 0, 0, .08);
    margin-right: .75rem;
    svg{
      width: .875rem;
      height: .75rem;
    }
  }
  &__value{
    margin-right: .875rem;
    font-weight: 300;
    color: $dark-grey;
    font-size: 1rem;
  }
}