.detail-main{
  margin: 3.25rem 0 .5rem;
  padding: 0 1.25rem;
  &__title{
    margin-bottom: .75rem;
    font-weight: 600;
    font-size: 1.25rem;
    color: $dark-blue;
  }
  &__txt{
    font-weight: 300;
    font-size: .9375rem;
    color: $dark-blue;
    p{margin-bottom: 1em;}
  }
}