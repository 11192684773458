.my-order{
  padding: 0 1.25rem 3rem;
  &__bar{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1.1875rem 0 1.875rem;
  }
  &__date{
    font-size: .875rem;
    color: $dark-grey;
  }
  &__status{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: .875rem;
    color: #509B35;
    &-icn{
      width: 1rem;
      height: 1rem;
      margin-right: .4375rem;
      svg{
        width: 100%;
        height: 100%;
      }
    }
  }
  &__section{
    margin-top: 1.6875rem;
    &-title{
      margin-bottom: .875rem;
      font-size: .875rem;
      color: $dark-blue;
      font-weight: 400;
    }
  }
}
.my-order-table{
  margin: 1.375rem 0 2.5rem;
  &__row{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: .625rem;    
    line-height: 140%;
  }
  &__cell{
    &:first-child{
      width: 8.125rem;
      font-size: .75rem;
      padding-right: .8125rem;
      color: $dark-grey;
    }
    &:last-child{
      width: calc(100% - 8.125rem);
      font-size: .875rem;
      color: $dark-blue;
    }
  }
}