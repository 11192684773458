.address-select{
  margin: 2.375rem 0 2.875rem;
}

.address-select-item{
  margin-bottom: .5rem;
  display: block;
  &__wrap{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: .75rem 1rem;
    background-color: $light-grey;
    border: 1px solid $grey;
    border-radius: .5rem;
  }
  input{
    display: none;
    &:checked + .address-select-item__wrap{
      background-color: $white;
      .address-select-item__dop{background-color: $dark-blue;}
    }
  }
  &__left{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__dop{
    margin-right: 1.125rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 50%;
    border: 1px solid $grey;
    background-color: $white;
    box-shadow: inset 0 0 0 2px $white, inset 0 0 0 3px $dark-blue;
    svg{
      display: block;
      width: .5rem;
      height: .375rem;
    }
  }
  &__name{
    font-weight: 600;
    font-size: 1.125rem;
    color: $dark-blue;
    white-space: nowrap;
  }
  &__right{
    flex-grow: 1;
    padding-left: 1rem;
  }
  &__txt{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    color: $black;
    font-weight: 300;
    font-size: .6875rem;
    text-align: right;
    padding-left: 1rem;
  }
}

.address-select-add{
  @include btn-reset;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 2rem;
  margin: 1.25rem 0 2.9375rem;
  &__icn{
    width: 1rem;
    height: 1rem;
    margin-right: 1rem;
  }
  &__txt{
    color: $yellow;
    font-weight: 600;
    font-size: .875rem;
    line-height: 2rem;
  }
}