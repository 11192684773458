.profile-form{
  margin-top: 1rem;
  padding-bottom: 1rem;
  &__group{
    background-color: $white;   
    margin-bottom: .75rem;
    &_tel{margin-bottom: 2.8125rem;}
  }
  &__footer{
    padding: 0 1.25rem;
    margin: 2.75rem 0;
  }
}
.profile-form-item{
  padding: 1.25rem;
  border-bottom: 1px solid $light-grey;
  display: block;
  width: 100%;
  position: relative;
  &:last-child{border-bottom: none;}
  &__title{
    display: block;
    font-size: .75rem;
    margin-bottom: .5rem;
    color: $dark-grey;
    font-weight: 300;
  }
  &__input{
    @include input-reset;
    font-weight: 300;
    &::-webkit-input-placeholder{opacity: 1; color: $grey;}
    &::-moz-placeholder{opacity: 1; color: $grey;}
    &:-moz-placeholder{opacity: 1; color: $grey;}
    &:-ms-input-placeholder{opacity: 1; color: $grey;}
    &:placeholder{opacity: 1;color: $grey;}
    &:focus:required:valid{color: $dark-blue;}
    &:required:valid{color: $dark-blue;}    
  }
  &__arrow{
    position: absolute;
    top: 50%;
    right: 1.25rem;
    width: 1.5rem;
    height: 1.5rem;
    svg{
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  &__status{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    left: 1.25rem;
    top: 100%;
    padding-top: .5rem;
    font-weight: 300;
    font-size: .6875rem;
    line-height: 1;
    &-icn{
      width: .75rem;
      height: .75rem;
      margin-right: .5rem;
      position: relative;
      top: -.05rem;
      svg{
        display: block;
        width: 100%;
        height: 100%;
      }
    }
    &.confirmed{color: $green;}
  }
}

.profile-exit{
  @include btn-reset;
  text-align: left;
  color: $red;
  font-size: 1rem;
  font-weight: 300;
  padding: 1.25rem;
}
.profile-form-add{
  @include btn-reset;
  padding: .5rem 1rem;
  background-color: $dark-blue;
  color: $white;
  font-size: .875rem;
  line-height: 1.5;
  border-radius: 1.125rem;
}