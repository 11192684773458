.registration{
  height: 100vh;
  background: center center no-repeat;
  background-size: cover;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
  color: $white;
  text-align: center;
  padding: 45px 1.25rem 1.25rem;
  &__title{
    font-weight: 600;
    font-size: 1.625rem;
    line-height: 150%;
    color: $white;
    margin-bottom: .75rem;
  }
  &__txt{
    font-size: .875rem;
    line-height: 130%;
    margin-bottom: 1.25rem;
    span{color: $dark-grey;}
  }
  &__footer{
    &-txt{
      margin: 1.125rem 0;
      font-weight: 300;
      font-size: .875rem;
      line-height: 140%;
      a{
        color: $white;
        text-decoration: underline;
      }
    }
  }
}
.registration-close{
  @include btn-reset;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  width: 2.5rem;
  height: 2.5rem;
}
.registration-phone{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 3.375rem;
  background-color: $light-grey;
  border-radius: .5rem;
  padding-top: .25rem;
  &__prefix{
    font-weight: 300;
    font-size: 1.25rem;
    color: $dark-blue;
    margin-right: .2rem;
  }
  &__input{
    @include input-reset;
    width: 10rem;
    font-weight: 300;
    font-size: 1.25rem;
    color: $dark-blue;
    &::-webkit-input-placeholder{opacity: 1; color: #d8d8d8;}
    &::-moz-placeholder{opacity: 1; color: #d8d8d8;}
    &:-moz-placeholder{opacity: 1; color: #d8d8d8;}
    &:-ms-input-placeholder{opacity: 1; color: #d8d8d8;}
    &:placeholder{opacity: 1;color: #d8d8d8;}
    &:focus:required:valid{color: $dark-blue;}
    &:required:valid{color: $dark-blue;}
    
  }
}