.notice{
  border-top: 1px solid $clr-border;
  margin: 2.625rem 0;
}

.notice-item{
  display: block;
  border-bottom: 1px solid $clr-border;
  padding: 1.75rem 1.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &__txt{
    font-weight: 300;
    font-size: 1rem;
    color: $dark-blue;
  }
  &__dop{
    display: block;
    width: 2rem;
    height: 1.25rem;
    position: relative;
    border-radius: .5125rem;
    background-color: $grey;
    transition: all .25s;
    &::before{
      content: '';
      display: block;
      position: absolute;
      border-radius: 50%;
      width: 1.25rem;
      height: 1.25rem;
      background-color: $white;
      transition: all .25s;
      box-shadow: 0 1px 2px #C4C4C4;
      top: 0;
      left: -1px;
    }
  }
  input{
    display: none;
    &:checked + .notice-item__dop{
      background-color: rgba(251, 186, 0, .3);
      &::before{
        left: calc(1rem + 1px);
        background-color: $yellow;
      }
    }
    
  }
}