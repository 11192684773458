.about-app{
  min-height: calc(100vh - 9rem);
  padding-bottom: 2rem;
  text-align: center;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-direction: column;
  &__img{
    margin: 4.25rem auto 3.375rem;
    width: 11.5625rem;
  }
  &__content{padding: 0 1.25rem;}
  &__txt{
    margin-bottom: 2rem;
    font-weight: 300;
    font-size: 1rem;
    line-height: 180%;
    color: $black;
  }
  &__rulslink{
    font-size: 1.25rem;
    color: $blue;
  }
  &__footer{
    margin-top: 2rem;
    &-link{
      font-size: .875rem;
      color: $blue;
    }
  }
}