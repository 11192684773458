.descriptions{
  &__main{
    background-color: $clr-dop-main;
    padding: 1.375rem 1.25rem 3.75rem;
  }
}

.description{
  font-weight: 300;
  font-size: .75rem;
  color: $dark-blue;
  display: none;
  &.active{display: block;}
  p{
    margin-bottom: 1.2em;
    strong, b{
      font-weight: 400;
      color: $black;
    }
    span{color: $dark-grey;}
  }
  &__row{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    color: $dark-grey;
  }
  h3{
    margin-bottom: 1.2em;
    font-weight: 600;
    font-size: .875rem;
  }
}