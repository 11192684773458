.footer{
  position: fixed;
  bottom: -1px;
  left: 0;
  width: 100%;
  padding: .625rem .875rem 0;
  z-index: 600;
  border-radius: .75rem .75rem 0 0;
  filter: drop-shadow(0 -.25rem 1.25rem rgba(0, 0, 0, .06));
  background-color: $white;
}