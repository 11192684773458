.about{
  background: url(../img/pic/about.jpg) center 0 no-repeat $black;
  background-size: cover;
  color: $white;
  padding: 3rem 0 9.5rem;
  &__main{margin: 0 1.25rem;}
  &__title{
    @include h2;
    margin-bottom: 1.5rem;
    color: $white;
  }
  &__txt{
    font-weight: 300;
    font-size: 1rem;
    line-height: 130%;
    padding-bottom: 3.188rem;
  }
  &__links{
    border-top: 1px solid $light-grey;
    li{border-bottom: 1px solid $light-grey;}
  }
  &__link{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.125rem 1.25rem;
    color: $white;
    font-size: 1.25rem;
    line-height: 1.5rem;
    &-icn{
      svg{
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }
}