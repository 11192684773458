.address-section{
  margin: 2.125rem 0;
  padding: 0 1.25rem;
  &__title{
    @include h2;
    margin-bottom: .6875rem;
  }
}
.address-section-btn{
  @include btn-reset;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: #324055;
  border-radius: .5rem;
  height: 3.375rem;
  max-width: 25.875rem;
  margin: 0 auto;
  font-weight: 300;
  font-size: 1.25rem;
  color: $white;
  &__icn{
    display: block;
    width: 2.125rem;
    height: 2rem;
    margin-left: 1.25rem;
    position: relative;
    top: -.1rem;
    svg{
      width: 100%;
      height: 100%;
    }
  }
}