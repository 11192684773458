.switch-nav{
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  padding: .25rem;
  background: $light-grey;
  border-radius: .5rem;
  margin: 1.25rem 0 1rem;
  &__item{
    @include btn-reset;
    width: 50%;
    height: 2.875rem;
    line-height: 2.875rem;
    text-align: center;
    border-radius: .3125rem;
    font-size: .875rem;
    padding-top: .1rem;
    color: $dark-blue;
    &.active{background-color: $yellow;}
  }
}