.my-orders{
  margin-top: 1.25rem;
  padding: 0 1.25rem 3.75rem;
}

.my-orders-item{
  margin-bottom: 1.25rem;
  padding: 1.25rem;
  background-color: $white;
  box-shadow: 0 1px 1.5rem rgba(0, 0, 0, .12);
  border-radius: .5rem;
  &__header,
  &__footer{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__number{
    font-size: 1rem;
    color: $black;
  }
  &__date{
    font-size: .875rem;
    color: $dark-grey;
  }
  &__main{margin: 1.375rem 0 1.75rem;}
  &__price{
    font-size: .875rem;
    color: #9B9B9B;
    span{
      font-size: 1rem;
      color: #2222;
    }
  }
  .profile-btn{width: 6.75rem;}
  &__status{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: .875rem;
    &-icn{
      width: 1rem;
      height: 1rem;
      margin-right: .4375rem;
    }
    &.ready{color: $green;}
  }
}