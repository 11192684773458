.tab-nav{
  overflow-x: auto;
  &::-webkit-scrollbar {
    height: 0;
    display: none;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
    background-color: transparent;
    border: none;
    box-shadow: none;
    outline: none;
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    background: transparent;
    background-color: transparent;
    border-radius: 0;
    border: 0 solid transparent;
    display: none;
    &:hover {
  	  background: transparent;
      background-color: transparent;
      display: none;
    }
  } 
  ul{
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    border-bottom: 1px solid $clr-border;
    li{flex-grow: 1;}
  }
  &__link{
    display: block;
    text-align: center;
    white-space: nowrap;
    position: relative;
    padding: 0 1rem 1rem;
    font-weight: 300;
    font-size: .9375rem;
    color: #222;
    min-width: 5.375rem;
    &.active{
      font-weight: 600;
      &::after{
        content: '';
        display: block;
        width: 100%;
        height: 3px;
        background-color: $yellow;
        position: absolute;
        bottom: -1px;
        left: 0;
      }
    }
  }
}