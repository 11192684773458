.nav{
  margin-bottom: .1875rem;
  margin-top: 1.5rem;
  &:first-child{margin-top: 0;}
  ul{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
}

.nav-item{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  text-align: center;
  width: 3.75rem;
  &__txt{
    margin-top: .25rem;
    font-weight: 300;
    font-size: .6875rem;
    letter-spacing: .06em;
    color: $dark-grey;
  }
  &__icn{
    svg{
      width: 100%;
      height: 100%;
      fill: $grey;
    }
    &_home{
      width: 1.625rem;
      height: 1.5625rem;
    }
    &_menu{
      width: 1.6875rem;
      height: 1.9375rem;
    }
    &_shopcart{
      width: 1.3125rem;
      height: 1.6875rem;
    }
    &_map{
      width: 1.375rem;
      height: 1.6875rem;
    }
    &_profile{
      width: 1.5625rem;
      height: 1.6875rem;
    }
  }
  &.active{
    .nav-item__txt{color: $dark-blue;}
    .nav-item__icn{
      svg{fill: $yellow;}
    }
  }
}