.datapicker{
  margin: 2rem 0;  
  width: 100vw;
  margin-left: -1.25rem;
  &__slider{
    height: 18.5rem;
    position: relative;
    &::before{
      content: '';
      display: block;
      position: absolute;
      left: 0;
      width: 100%;
      height: .5rem;
      z-index: 10;
      background: linear-gradient(to bottom, #fff 0%, rgba(255, 255, 255, 0) 100%);
    }
    .swiper-slide{height: 2.5rem !important;}
    .swiper-slide-active{
      height: 3.75rem !important;
      .datapicker__slide{
        font-size: 1.125rem;
        font-weight: 600;
        border-top-color: $grey;
        border-bottom-color: $grey;
        color: $black;
      }
    }
    .swiper-slide-prev,
    .swiper-slide-next{
      .datapicker__slide{color: $dark-blue;}
    }
    .swiper-slide.fade,
    .swiper-slide-next + .swiper-slide{
      .datapicker__slide{color: $dark-grey;}
    }
  }
  &__slide{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-weight: 300;
    font-size: .875rem;
    line-height: 140%;
    text-align: center;
    
    transition: font-size .25s, font-weight .25s, border-color .25s, color .75s;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    padding-top: .25rem;
    color: $grey;
  }
}