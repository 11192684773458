.slideshow{
  position: relative;
  margin-bottom: 3.25rem;
  overflow: visible;
  &__pagination{
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    padding-top: .5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    
    .swiper-pagination-bullet{
      display: block;
      width: .75rem;
      height: .75rem;
      border-radius: 50%;
      margin: 0 .25rem;
      background-color: $grey;
      outline: none;
      border: none;
      &:focus{outline: none;}
      &.swiper-pagination-bullet-active{background-color: $dark-blue;}
    }
  }
}
.slideshow-slide{
  width: 100%;
  padding-top: 77.7%;  
  background: center center no-repeat;
  background-size: cover;
}