.carusel-sec{
  margin: 1rem 0 2.5rem;
  padding: 0 1.25rem;
  &__header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
  &__title{@include h2;}
  &__link{
    font-weight: 600;
    font-size: .8125rem;
    color: $grey;
  }
  &__name{
    margin-bottom: .875rem;
    font-weight: 600;
    font-size: .875rem;
    color: $dark-blue;
  }
}