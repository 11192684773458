.header{
  border-top: 1px solid rgba(153, 153, 153, .3);
  border-bottom: 1px solid rgba(153, 153, 153, .3);
  padding: 0 1.25rem 0 .25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-logo{
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 7.625rem;
  height: 3.75rem;
}