.favorite-item{
  margin-bottom: 1.25rem;  
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  border-radius: .5rem;
  background-color: $white;
  filter: drop-shadow(0 1px 1.5rem rgba(0, 0, 0, .12));
  &__pic{
    width: 7.5rem;
    padding-top: 7.5rem;
    background: center center no-repeat;
    background-size: cover;
    overflow: hidden;
    border-radius: .5rem 0 0 .5rem;
  }
  &__main{
    width: calc(100% - 7.5rem);
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-direction: column;
    position: relative;
    padding: 1.1875rem 2.5rem .9375rem 1.125rem;
    border-radius: 0 .5rem .5rem 0;
  }
  &__title{
    font-weight: 600;
    font-size: 1rem;
    color: $black;
  }
  &__txt{
    font-size: .8125rem;
    color: $dark-grey;
  }
  &__close{
    @include btn-reset;
    width: 2.5rem;
    height: 2.5rem;
    position: absolute;
    top: 0;
    right: 0;
    svg{
      width: 100%;
      height: 100%;
    }
  }
  &__add-in-card{
    @include btn-reset;
    width: 2.25rem;
    height: 2.25rem;
    position: absolute;
    bottom: -.5rem;
    right: 0;
    border-radius: 50%;
    background-color: $red;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 .25rem .25rem rgba(219, 48, 34, .16);
    svg{
      width: .875rem;
      height: 1.0625rem;
    }
  }
  &__price{
    font-size: 1.625rem;
    color: $dark-blue;
    position: absolute;
    bottom: .6rem;
    right: .5rem;
    white-space: nowrap;
    span{font-size: .75rem;}
  }
}

